<template>
  <section>
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="是否启用">
        <el-select clearable v-model="searchForm.deleted" placeholder="请选择">
          <el-option
            v-for="item in option"
            :key="item.num"
            :label="item.name"
            :value="item.num"
            @keyup.enter.native="search"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" type="primary" size="mini">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
      :row-class-name="tableRowClassName"
    >
      <el-table-column align="center" prop="deleted" label="是否启用">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.deleted"
            :active-value="0"
            :inactive-value="1"
            active-color="#13ce66"
            inactive-color="#e6e6e6"
            @change="handleStatusChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="channelName"
        label="渠道名称"
      ></el-table-column>

      <el-table-column align="center" prop="orderValue" label="渠道排序">
        <template slot-scope="scope">
          <el-input
            v-model.number="scope.row.orderValue"
            @blur="setInput(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="isBase" label="自有">
        <template slot-scope="scope">
          <el-checkbox @change="cheBox(scope.row)"
                       v-model="scope.row.isBase"
                       :true-label=1
                       :false-label=0></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="minAmt" label="最小(含)">
        <template slot-scope="scope">
          <el-input
            v-model.number="scope.row.minAmt"
            @blur="setInputMin(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="maxAmt" label="最大(不含)">
        <template slot-scope="scope">
          <el-input
            v-model.number="scope.row.maxAmt"
            @blur="setInputMax(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="feeRate" label="费率(%)">
        <template slot-scope="scope">
          <el-input v-model.number="scope.row.feeRate"></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="feeFix" label="固费(卢比)">
        <template slot-scope="scope">
          <el-input v-model.number="scope.row.feeFix"></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleSave(scope.row)"
            >保存</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
  </section>
</template>

<script>
import {
  withdraw,
  withdrawSave,
} from "@/api/merchantPayConfig/merchantPayRouter";

export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 100,
      dataTotal: 0,
      loading: false,
      tableData: [],
      searchForm: {
        deleted: 0,
      },
      option: [
        { num: 0, name: "启用" },
        { num: 1, name: "关闭" },
        { num: "", name: "全部" },
      ],
      sequence: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex) {
        return "warning-row";
      } else if (rowIndex === 0) {
        return "warning-row";
      }
      return "";
    },

    getList() {
      withdraw({
        content: { deleted: this.searchForm.deleted, currency: "INR" },
      }).then((res) => {
        if (res.code === "0000") {
          this.loading = false;
          this.tableData = res.data;
          this.tableData.forEach((item) => {
            this.sequence.push(item.orderValue);
          });
        }
      });
    },
    handleSave(row) {
      withdrawSave({
        content: {
          orderValue: row.orderValue,
          minAmt: row.minAmt,
          maxAmt: row.maxAmt,
          isBase: row.isBase,
          isUpi: row.isUpi,
          feeRate: row.feeRate,
          feeFix: row.feeFix,
          id: row.id,
        },
      }).then((res) => {
        if (res.code) {
          let fv =
            res.code === "0000" ? this.$message.success : this.$message.error;
          fv(res.message);
          this.getList();
        }
      });
    },
    handleStatusChange(row) {
      withdrawSave({
        content: { deleted: row.deleted, id: row.id },
      }).then((res) => {
        if (res.code === "0000") {
          this.$message.success("操作成功！");
          this.getList();
        } else {
          this.$message.error(row.message);
        }
      });
    },
    setInput(row) {
      if (row.orderValue < 0) {
        row.orderValue = 0;
      }

      // if(this.sequence.includes(row.orderValue)){
      //   row.orderValue = ''
      //   this.$message.error('不能与其他排序相同')
      // }
    },

    setInputMin(row) {
      if (row.minAmt < 0 || row.minAmt == "") {
        row.minAmt = 0;
      }
      if (row.minAmt >= row.maxAmt && row.maxAmt !== 0) {
        row.minAmt = row.maxAmt - 1;
        this.$message.error("最小值需小于最大值");
      }
    },
    setInputMax(row) {
      if (row.maxAmt < 0) {
        row.maxAmt = 0;
      }
      if (row.maxAmt <= row.minAmt) {
        row.maxAmt = row.minAmt + 1;
        this.$message.error("最大值需大于最小值");
      }
    },
    search() {
      this.pageIndex = 1;
      this.getList();
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
    },
    pageIndexChange(val) {
      this.pageIndex = val;
    },
  },
  components: {},
};
</script>

<style lang="scss">
.box {
  background-color: chocolate;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-input__inner {
  text-align: center;
}
</style>
